"use client";
import React from "react";
import { useState, Component} from "react";
import avatar from '../assets/images/R.gif'
import cuddle from '../assets/images/sweet-cuddle-bear-animation-605upg2l8nv7mbkn.gif'

export default function Page() {
  const [noCount, setNoCount] = useState(0);
  const [yesPressed, setYesPressed] = useState(false);
  const yesButtonSize = noCount * 20 + 16;

  const handleNoClick = () => {
    setNoCount(noCount + 1);
  };

  const getNoButtonText = () => {
    const phrases = [
      "No",
      "Please boooooo?",
      "What if we eat sushi?",
      "Pretty please",
      "With a yuzu sake on the side",
      "What about smooothieeee",
      "Pooooolice poopoo",
      "I am your boo right :(((((",
      "I see it now",
      "I see it so clearly",
      "Heart broken so many times, don't know what to believeeeee",
      "you don't love me, i SAD now",
      "cry cry I am crying",
      "Senora es bonita please",
      "Mi lady winking",
      "yes :) hehe that's the only option",
    ];

    return phrases[Math.min(noCount, phrases.length - 1)];
  };

  return (
    <div className="-mt-16 flex h-screen flex-col items-center justify-center">
      {yesPressed ? (
        <>
          <img src={cuddle} />
          <div className="my-4 text-4xl font-bold">Hehe let's be besties and nasties always : ))))</div>
        </>
      ) : (
        <>
          <img
            className="h-[200px]"
            src={avatar}
          />
          <h1 className="my-4 text-4xl">Will you be my Valentine?</h1>
          <div className="flex items-center">
            <button
              className={`mr-4 rounded bg-green-500 px-4 py-2 font-bold text-white hover:bg-green-700`}
              style={{ fontSize: yesButtonSize }}
              onClick={() => setYesPressed(true)}
            >
              Yes
            </button>
            <button
              onClick={handleNoClick}
              className=" rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
            >
              {noCount === 0 ? "No" : getNoButtonText()}
            </button>
          </div>
        </>
      )}
    </div>
  );
}